<template>
    <div v-editable="blok">
        <template v-for="(blokEl, index) in blok.body" :key="blokEl._uid">
            <Component
                :is="blokEl.component"
                v-if="index < 3"
                :blok="blokEl"
                :airport="airport"
                :products="products"
                :airport-stats="airportStats"
            />
            <Component
                :is="blokEl.component"
                v-if="index >= 3"
                :blok="blokEl"
                :airport="airport"
                :products="products"
                :airport-stats="airportStats"
            />
        </template>
    </div>
</template>

<script setup lang="ts">
import { median } from '@nuxt-monorepo/ui/utils/helpers';
import type { IAirportProducts, IAirportEntity, IAirportStats } from '../types';

const { blok, products, airport, airportStats } = defineProps<{
    blok: any;
    products?: IAirportProducts;
    airport?: IAirportEntity;
    airportStats?: IAirportStats | null | undefined;
}>();

// Set empty schemaList for useHead()
const schemaList: any[] = [];

const airportStats2: any = airportStats ?? {
    ratings: { surveys_count: 0, rating_by_customers: { accurate: 0 } },
};

if (!airportStats) {
    let survey_count = 0;
    const ratings = [];
    products?.hotels?.forEach((hotel) => {
        if (hotel.overview.surveys_count > 0) {
            survey_count += hotel.overview.surveys_count;
            ratings.push(hotel.overview.rating_by_customers.accurate);
        }
    });
    products?.parking?.forEach((parking) => {
        if (parking.ratings.comment_count > 0) {
            survey_count += parking.ratings.comment_count;
            ratings.push(Number(parking.ratings.rating));
        }
    });
    airportStats2.ratings.surveys_count = survey_count ?? 0;
    airportStats2.ratings.rating_by_customers.accurate = ratings?.length ? median(ratings) : 0;
}

// Check to see if schema data exists.
if (import.meta.server && blok.schema?.[0]?.data) {
    // Cycle through each schema to add to schemaList for <Head>
    blok.schema?.forEach((schema: { data: string }) => {
        // Set schema data in prepartion for mutation
        let result: any = schema.data;
        // Check to see if there are any variables within curly bracers '{}' within schema
        const matches: any = schema.data.match(/{(.*?)}/g);
        if (matches?.length) {
            // If matches are found, we must replaceAll that are matched with evalauted variable
            matches.forEach((match: string) => {
                try {
                    result = result.replaceAll(
                        match,
                        Function(`
                            function capitalize(string) {
                                if (string) {
                                    return string.charAt(0).toUpperCase() + string.slice(1);
                                }
                            };
                            const blok = ${JSON.stringify(blok)};
                            const products = ${JSON.stringify(products)};
                            const airport = ${JSON.stringify(airport)};
                            const airportStats = ${JSON.stringify(airportStats)};
                            const airportStats2 = ${JSON.stringify(airportStats2)};
                            return ${match?.substring(1, match?.length - 1)}
                            `)()
                    );
                } catch (e) {
                    console.error('Error in schema evaluation:', e);
                }
            });
        }
        result.replaceAll('null', '').replaceAll('undefined', '');
        if (result) {
            // Remove any empty keys from schema
            let parsed = JSON.parse(result);
            Object.keys(parsed)?.forEach((k) => {
                if (typeof parsed[k] === 'object') {
                    Object.keys(parsed[k]).forEach((k2) => {
                        if (parsed[k] && !parsed[k][k2] && parsed[k][k2] !== undefined) {
                            delete parsed[k][k2];
                            if (k === 'offers' && k2 === 'url') return;
                            delete parsed[k];
                        }
                    });
                } else {
                    return !parsed[k] && parsed[k] !== undefined && delete parsed[k];
                }
            });
            parsed = JSON.stringify(parsed, undefined, 4);
            // Add result to schemaList
            schemaList.push({ type: 'application/ld+json', innerHTML: parsed });
        }
    });
}

// Set Schema List to <Head>
// @ts-expect-error
useHead({
    script: schemaList,
});
</script>
